import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {WidgetRouteTimeFrameDay} from '@wildix/xbees-kite-client';

import {WidgetFormData} from '../../types';

interface WidgetWorkHoursTimezoneFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
}

interface DayDescription {
  id: WidgetRouteTimeFrameDay;
  label: string;
}

const DAYS: DayDescription[] = [
  {id: WidgetRouteTimeFrameDay.MONDAY, label: 'Monday'},
  {id: WidgetRouteTimeFrameDay.TUESDAY, label: 'Tuesday'},
  {id: WidgetRouteTimeFrameDay.WEDNESDAY, label: 'Wednesday'},
  {id: WidgetRouteTimeFrameDay.THURSDAY, label: 'Thursday'},
  {id: WidgetRouteTimeFrameDay.FRIDAY, label: 'Friday'},
  {id: WidgetRouteTimeFrameDay.SATURDAY, label: 'Saturday'},
  {id: WidgetRouteTimeFrameDay.SUNDAY, label: 'Sunday'},
  {id: WidgetRouteTimeFrameDay.EVERY_DAY, label: 'Every Day'},
  {id: WidgetRouteTimeFrameDay.WEEKDAYS, label: 'Weekdays'},
  {id: WidgetRouteTimeFrameDay.WEEKENDS, label: 'Weekends'},
];

export default function WidgetWorkHoursTimeFrameDayField(props: WidgetWorkHoursTimezoneFieldProps) {
  const {form, path} = props;
  const {control, formState, trigger, watch} = form;
  const {isSubmitting} = formState;

  // const value123 = watch(path as any);
  //
  // console.log('value123', value123);

  return (
    <Controller
      control={control}
      name={path as any}
      rules={{required: true}}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Day</InputLabel>
          <Select
            disabled={isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value}
            label="Day"
            onChange={(event) => {
              onChange(event);
              trigger(name);
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {DAYS.map(({id, label}) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
