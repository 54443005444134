import React from 'react';

import {Box, Stack} from '@mui/material';
import {StackProps} from '@mui/material/Stack/Stack';

interface SectionTitleProps extends StackProps {
  title: string;
}

export default function SectionTitle(props: SectionTitleProps) {
  const {title, ...stackProps} = props;

  return (
    <Stack flexDirection="row" alignItems="center" {...stackProps}>
      <Box sx={{fontSize: '16px', fontWeight: '500'}}>{title}</Box>
      <Box sx={{height: '1px', background: 'rgba(174, 175, 177, 1)', flex: '1', marginLeft: '24px'}} />
    </Stack>
  );
}
