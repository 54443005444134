import {useQuery} from 'react-query';

import {GetWidgetCommand} from '@wildix/xbees-kite-client';

import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';

export default function useGetKiteWidgetQuery(widgetId?: string) {
  return useQuery(
    ['kite/widgets', widgetId],
    () => getXbsKiteClient().send(new GetWidgetCommand({widgetId: widgetId || ''})),
    {
      retry: false,
      enabled: !!widgetId,
    },
  );
}
