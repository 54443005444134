import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Alert, Box, IconButton, InputAdornment, TextField} from '@mui/material';

import CopyIcon24 from '../../../base/icons/CopyIcon24';
import getWidgetInstallationCode from '../helpers/getWidgetInstallationCode';
import getWidgetUrl from '../helpers/getWidgetUrl';
import {WidgetFormData} from '../types';

interface WidgetInstallationInstructionsFormProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetInstallationInstructionsForm(props: WidgetInstallationInstructionsFormProps) {
  const {form} = props;
  const id = form.watch('id');
  const displayId = id ? id : '00000000';

  const isDisabled = !id;

  const code = getWidgetInstallationCode(displayId);
  const url = getWidgetUrl(displayId);

  return (
    <Box mt={2}>
      {isDisabled && (
        <Alert severity="warning">
          Installation instructions will be available once you <b>save</b> the widget.
        </Alert>
      )}

      <Box mt={2}>
        <Box sx={{fontWeight: 500, fontSize: '16px', lineHeight: '24px', mb: 1}}>Share with customers</Box>
        <TextField
          size="small"
          value={url}
          disabled={isDisabled}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end" sx={{marginRight: '-12px'}}>
                <IconButton disabled={isDisabled} onClick={() => navigator.clipboard.writeText(url)}>
                  <CopyIcon24 />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
        />
      </Box>

      <Box mt={4}>
        <Box sx={{fontWeight: 500, fontSize: '16px', lineHeight: '24px', mb: 1}}>Set up a website widget</Box>
        <Box sx={{color: '#666'}}>Follow these steps to add the chat widget to your website:</Box>
        <Box component="ol" sx={{pl: 2, mb: 2}}>
          <li>Copy the code snippet below</li>
          <li>
            Locate the <b>{'</body>'}</b> tag in your website's HTML
          </li>
          <li>Paste the code immediately before the {'</body>'} tag</li>
          <li>Add this to every page where you want the widget to appear</li>
        </Box>

        <TextField
          multiline
          fullWidth
          value={code}
          disabled={isDisabled}
          InputProps={{
            readOnly: true,
            sx: {
              fontFamily: 'monospace',
            },
            endAdornment: (
              <InputAdornment position="end" sx={{alignSelf: 'flex-start', mt: 1, mr: -0.5}}>
                <IconButton disabled={isDisabled} onClick={() => navigator.clipboard.writeText(code)} size="small">
                  <CopyIcon24 />
                </IconButton>
              </InputAdornment>
            ),
          }}
          minRows={4}
          variant="outlined"
        />
      </Box>
    </Box>
  );
}
