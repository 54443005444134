import React, {useCallback, useRef, useState} from 'react';
import {ChromePicker, ColorResult, RGBColor} from 'react-color';
import {UseFormReturn} from 'react-hook-form';

import {Box, Popover, Stack, styled} from '@mui/material';
import Button from '@mui/material/Button';

import {isString} from 'lodash';

import {WidgetFormData} from '../../types';

interface WidgetAppearanceColorFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
  defaultColor: string;
}

function colorToStringConvert(colorObj: RGBColor) {
  return `rgba(${colorObj.r}, ${colorObj.g}, ${colorObj.b}, ${colorObj.a})`;
}

const ChromePickerStyled = styled(ChromePicker)({
  boxShadow: 'none !important',
});

export default function WidgetAppearanceColorField(props: WidgetAppearanceColorFieldProps) {
  const {form, path, defaultColor} = props;
  const {formState, watch, setValue} = form;
  const {isSubmitting} = formState;

  const selectedColor = watch(path as any) as string | undefined;
  const color = selectedColor || defaultColor;
  const [showColor, setShowColor] = useState<boolean>(false);

  const btnRef = useRef(null);

  const onChangeClick = useCallback(() => {
    setShowColor(true);
  }, []);

  const onColorChange = useCallback((colorObj: ColorResult | string | undefined) => {
    let result: string | undefined;

    if (colorObj === undefined) {
      result = undefined;
    } else {
      result = isString(colorObj) ? colorObj : colorToStringConvert(colorObj.rgb);
    }

    setValue(path as any, result);
  }, []);

  return (
    <Stack mt={1} flexDirection="row" alignItems="center">
      <Box sx={{width: '24px', height: '24px', borderRadius: '12px', backgroundColor: color}} />
      <Button
        disabled={isSubmitting}
        ref={btnRef}
        variant="outlined"
        size="small"
        sx={{marginLeft: '16px'}}
        onClick={onChangeClick}>
        Change
      </Button>
      <Popover
        anchorEl={btnRef.current}
        open={showColor}
        onClose={() => setShowColor(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <ChromePickerStyled onChange={onColorChange} color={color} disableAlpha />
        <Box m={1}>
          <Button
            fullWidth
            data-qa="resetColorBtn"
            disabled={color === defaultColor}
            variant="outlined"
            size="small"
            onClick={() => onColorChange(undefined)}>
            Reset
          </Button>
        </Box>
      </Popover>
    </Stack>
  );
}
