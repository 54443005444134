import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {get} from 'lodash';

import {WidgetFormData} from '../../types';

interface WidgetTopTitleFieldProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetTopTitleField(props: WidgetTopTitleFieldProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, 'appearance.text.topTitle');

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          fullWidth
          label="Header title"
          placeholder="Hello"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="appearance.text.topTitle"
    />
  );
}
