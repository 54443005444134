import {useQuery} from 'react-query';

import {ListServicesCommand} from '@wildix/xbees-kite-client';

import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';

export default function useListKiteServicesQuery() {
  return useQuery('kite/services', () => getXbsKiteClient().send(new ListServicesCommand({})), {
    retry: false,
  });
}
