import React, {useEffect, useMemo, useState} from 'react';

import {Box, CircularProgress} from '@mui/material';

import {CreateOrUpdateWidgetPreviewCommand, CreateOrUpdateWidgetPreviewCommandInput} from '@wildix/xbees-kite-client';

import {cloneDeep, isEmpty, isEqual} from 'lodash';

import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';
import getWidgetDomain from '../helpers/getWidgetDomain';
import getWidgetFromWidgetFormData from '../helpers/getWidgetFromWidgetFormData';
import {WidgetFormData} from '../types';

import styled from '@emotion/styled';

const PREVIEW_DEBOUNCE_MS = 1000;

// Define base URL as a constant
const KITE_PREVIEW_BASE_URL = `https://${getWidgetDomain()}/kite`;

// Better name for preview frame dimensions
const PREVIEW_FRAME_DIMENSIONS = {
  width: '400px',
  height: '540px',
} as const;

interface KitePreviewFrameProps {
  widget: WidgetFormData;
}

const PreviewWrapper = styled(Box)`
  width: ${PREVIEW_FRAME_DIMENSIONS.width};
  height: ${PREVIEW_FRAME_DIMENSIONS.height};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(249, 249, 249, 1);
  border-radius: 8px;
  color: rgba(105, 105, 105, 1);
  box-shadow: 0px 4px 12px 0px rgba(37, 75, 102, 0.22);
`;

const LoaderOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(249, 249, 249, 0.7);
  border-radius: 8px;
  z-index: 1;
  opacity: 0.5;
`;

const PreviewFrame = styled.iframe`
  width: ${PREVIEW_FRAME_DIMENSIONS.width};
  height: ${PREVIEW_FRAME_DIMENSIONS.height};
  background-color: white;
  border: 0px;
  border-radius: 8px;
`;

function isPreviewEnabled(data: WidgetFormData): boolean {
  if (data.outOfWorkHoursTarget) {
    const target =
      data.outOfWorkHoursTarget.agent?.email ||
      data.outOfWorkHoursTarget.bot?.id ||
      data.outOfWorkHoursTarget.service?.id;

    if (!target) {
      return false;
    }
  }

  if (data.workHoursTarget) {
    const target =
      data.workHoursTarget.agent?.email || data.workHoursTarget.bot?.id || data.workHoursTarget.service?.id;

    if (target) {
      return true;
    }
  }

  return false;
}

interface KiteFrameProps {
  url: string;
  disabled: boolean;
}

const KiteFrame = React.memo((props: KiteFrameProps) => {
  const {url, disabled} = props;
  const [isFrameLoading, setIsFrameLoading] = useState(true);

  return (
    <>
      {(disabled || isFrameLoading) && <LoaderOverlay />}
      <PreviewFrame title="Preview" src={url} onLoad={() => setIsFrameLoading(false)} />
    </>
  );
});

export default function KitePreviewFrame(props: KitePreviewFrameProps) {
  const {widget} = props;

  const [previewUrl, setPreviewUrl] = useState<string>();
  const [previewWidgetOptions, setPreviewWidgetOptions] = useState<CreateOrUpdateWidgetPreviewCommandInput>();
  const [previewWidgetFormData, setPreviewWidgetFormData] = useState<WidgetFormData>();
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isPreviewDebouncing, setIsPreviewDebouncing] = useState(false);

  const isEnabled = isPreviewEnabled(widget);

  // This ID is used to track the temporary widget during preview
  const temporaryPreviewId = useMemo(() => `tmp${Math.random().toString().slice(2, 7)}`, []);

  // Handle form data changes
  useEffect(() => {
    if (isEmpty(widget)) {
      return;
    }

    if (!isEnabled) {
      return;
    }

    if (isEqual(widget, previewWidgetFormData)) {
      return;
    }

    try {
      const widgetOptions = getWidgetFromWidgetFormData(widget);
      const previewOptions = {
        ...widgetOptions,
        name: 'Preview',
        temporaryWidgetId: temporaryPreviewId,
      };

      if (isInitialRender) {
        setPreviewWidgetFormData(cloneDeep(widget));
        setPreviewWidgetOptions(previewOptions);
        setIsInitialRender(false);

        return;
      }

      setIsPreviewDebouncing(true);
      const debounceTimer = setTimeout(() => {
        setPreviewWidgetFormData(cloneDeep(widget));
        setPreviewWidgetOptions(previewOptions);
        setIsPreviewDebouncing(false);
      }, PREVIEW_DEBOUNCE_MS);

      return () => {
        clearTimeout(debounceTimer);
        setIsPreviewDebouncing(false);
      };
    } catch (error) {
      // Ignore.
      console.error(error);
    }
  }, [widget, isEnabled, temporaryPreviewId, isInitialRender]);

  // Update preview
  useEffect(() => {
    if (!previewWidgetOptions) {
      return;
    }

    (async () => {
      try {
        const kiteClient = getXbsKiteClient();
        const response = await kiteClient.send(new CreateOrUpdateWidgetPreviewCommand(previewWidgetOptions));
        // Add random sequence to prevent caching
        const previewUrlWithCache = `${KITE_PREVIEW_BASE_URL}/${response.widget.id}?start=true&seq=${Math.random()}`;
        setPreviewUrl(previewUrlWithCache);
      } catch (error) {
        // Ignore
        console.error(error);
      }
    })();
  }, [previewWidgetOptions]);

  if (!previewUrl && isEnabled) {
    return (
      <PreviewWrapper>
        <LoaderOverlay>
          <CircularProgress />
        </LoaderOverlay>
      </PreviewWrapper>
    );
  }

  if (!previewUrl) {
    return (
      <PreviewWrapper>
        <Box>Preview is not available</Box>
      </PreviewWrapper>
    );
  }

  return (
    <PreviewWrapper>
      <KiteFrame key={previewUrl} url={previewUrl} disabled={!isEnabled || isPreviewDebouncing} />
    </PreviewWrapper>
  );
}
