import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {WidgetFormData} from '../../types';

export const timezones = [
  {tzCode: 'Etc/GMT+12', label: 'GMT-12:00'},
  {tzCode: 'Etc/GMT+11', label: 'GMT-11:00'},
  {tzCode: 'Etc/GMT+10', label: 'GMT-10:00'},
  {tzCode: 'Etc/GMT+9', label: 'GMT-09:00'},
  {tzCode: 'Etc/GMT+8', label: 'GMT-08:00'},
  {tzCode: 'Etc/GMT+7', label: 'GMT-07:00'},
  {tzCode: 'Etc/GMT+6', label: 'GMT-06:00'},
  {tzCode: 'Etc/GMT+5', label: 'GMT-05:00'},
  {tzCode: 'Etc/GMT+4', label: 'GMT-04:00'},
  {tzCode: 'Etc/GMT+3', label: 'GMT-03:00'},
  {tzCode: 'Etc/GMT+2', label: 'GMT-02:00'},
  {tzCode: 'Etc/GMT+1', label: 'GMT-01:00'},
  {tzCode: 'Etc/GMT', label: 'GMT'},
  {tzCode: 'Etc/GMT-1', label: 'GMT+01:00'},
  {tzCode: 'Etc/GMT-2', label: 'GMT+02:00'},
  {tzCode: 'Etc/GMT-3', label: 'GMT+03:00'},
  {tzCode: 'Etc/GMT-4', label: 'GMT+04:00'},
  {tzCode: 'Etc/GMT-5', label: 'GMT+05:00'},
  {tzCode: 'Etc/GMT-6', label: 'GMT+06:00'},
  {tzCode: 'Etc/GMT-7', label: 'GMT+07:00'},
  {tzCode: 'Etc/GMT-8', label: 'GMT+08:00'},
  {tzCode: 'Etc/GMT-9', label: 'GMT+09:00'},
  {tzCode: 'Etc/GMT-10', label: 'GMT+10:00'},
  {tzCode: 'Etc/GMT-11', label: 'GMT+11:00'},
  {tzCode: 'Etc/GMT-12', label: 'GMT+12:00'},
  {tzCode: 'Etc/GMT-13', label: 'GMT+13:00'},
  {tzCode: 'Etc/GMT-14', label: 'GMT+14:00'},
];

interface WidgetWorkHoursTimezoneFieldProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetWorkHoursTimezoneField(props: WidgetWorkHoursTimezoneFieldProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {isSubmitting} = formState;

  return (
    <Controller
      control={control}
      name="workHoursRulesTimeZone"
      rules={{required: true}}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Time zone</InputLabel>
          <Select
            disabled={isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value}
            label="Time zone"
            onChange={(event) => {
              onChange(event);
              trigger(name);
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {timezones.map((tz) => (
              <MenuItem key={tz.tzCode} value={tz.tzCode}>
                {tz.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
