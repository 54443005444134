import React, {useCallback, useState} from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';

import RemoveIcon20 from '../../../../base/icons/RemoveIcon20';
import {
  DirectionMap,
  DirectionMapPrefix,
  MsPresenceSettingsMapsItemProps,
  MsPresenceStatusType,
  PbxPresenceStatusType,
  PresenceMap,
  SpecialPresenceStatusType,
} from '../../types';

const activeColor = '#757679';
const passiveColor = '#AEAFB1';

const RowBox = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ColumnBox = styled(Box)({
  fontSize: '14px',
  color: activeColor,
});

const StyledMenuItem = styled(MenuItem)({
  size: '14px',
  color: '#292C31',
});

const ListSubheaderStyled = styled(ListSubheader)({
  size: '12px',
  color: activeColor,
});

export default function MicrosoftPresenceIntegrationSettingsMaps(props: MsPresenceSettingsMapsItemProps) {
  const {presenceMap, mapping, setPresenceMap, onDelete, onAdd, isAddButtonVisible} = props;
  const [localPresenceMap, setLocalPresenceMap] = useState<PresenceMap>(presenceMap);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setLocalPresenceMap((prevState) => {
        const isPbxStatus = (value: string) => value.includes(DirectionMapPrefix.PBX);
        let newLocalPresenceMap;
        let toStatus = prevState.to;

        if (event.target.name === 'fromStatus') {
          if (
            (isPbxStatus(event.target.value) && isPbxStatus(toStatus)) ||
            (!isPbxStatus(event.target.value) && !isPbxStatus(toStatus))
          ) {
            toStatus = '';
          }

          newLocalPresenceMap = {
            ...prevState,
            direction: isPbxStatus(event.target.value) ? DirectionMap.PBX_TO_TEAMS : DirectionMap.TEAMS_TO_PBX,
            from: event.target.value,
            to: toStatus,
          };
        } else {
          newLocalPresenceMap = {
            ...prevState,
            to: event.target.value,
          };
        }

        const isLocalPresenceFromValid = newLocalPresenceMap.from && newLocalPresenceMap.from !== presenceMap?.from;
        const isLocalPresenceToValid = newLocalPresenceMap.to && newLocalPresenceMap.to !== presenceMap?.to;

        if (
          (isLocalPresenceFromValid && newLocalPresenceMap.to) ||
          (isLocalPresenceToValid && newLocalPresenceMap.from)
        ) {
          setPresenceMap(newLocalPresenceMap);
        }

        return newLocalPresenceMap;
      });
    },
    [presenceMap, setLocalPresenceMap, setPresenceMap],
  );

  const listPbxStatuses = mapping
    .map(({direction, from}) =>
      direction === DirectionMap.PBX_TO_TEAMS ? from.split(DirectionMapPrefix.PBX)[1] : null,
    )
    .filter(Boolean);

  const listMsTeamsStatuses = mapping
    .map(({direction, from}) =>
      direction === DirectionMap.TEAMS_TO_PBX ? from.split(DirectionMapPrefix.TEAMS)[1] : null,
    )
    .filter(Boolean);

  const isDirectionTeamsToPbx = localPresenceMap.direction === DirectionMap.TEAMS_TO_PBX;

  return (
    <Grid container sx={{m: 0}}>
      <RowBox>
        <ColumnBox>From</ColumnBox>
        <FormControl size="small" sx={{m: 2, width: 300}}>
          <InputLabel id="fromStatus">Status</InputLabel>
          <Select
            labelId="fromStatus"
            label="Status"
            name="fromStatus"
            size="small"
            value={localPresenceMap.from}
            onChange={handleChange}
            renderValue={(selected) => selected}>
            <ListSubheaderStyled>PBX</ListSubheaderStyled>
            {Object.values(PbxPresenceStatusType).map((name) => (
              <StyledMenuItem
                disabled={listPbxStatuses.includes(name)}
                key={name}
                value={`${DirectionMapPrefix.PBX}${name}`}>
                {name}
              </StyledMenuItem>
            ))}
            <ListSubheaderStyled>MS Teams</ListSubheaderStyled>
            {Object.values(MsPresenceStatusType).map((name) => (
              <StyledMenuItem
                disabled={listMsTeamsStatuses.includes(name)}
                key={name}
                value={`${DirectionMapPrefix.TEAMS}${name}`}>
                {name}
              </StyledMenuItem>
            ))}
          </Select>
        </FormControl>
      </RowBox>
      <RowBox>
        <ColumnBox>to</ColumnBox>
        <FormControl size="small" sx={{m: 2, width: 300}}>
          <InputLabel id="toStatus">Status</InputLabel>
          <Select
            labelId="toStatus"
            label="Status"
            name="toStatus"
            size="small"
            value={localPresenceMap.to}
            onChange={handleChange}
            renderValue={(selected) => selected}>
            <ListSubheaderStyled sx={{color: isDirectionTeamsToPbx ? activeColor : passiveColor}}>
              PBX
            </ListSubheaderStyled>
            {Object.values(PbxPresenceStatusType).map((name) => (
              <StyledMenuItem disabled={!isDirectionTeamsToPbx} key={name} value={`${DirectionMapPrefix.PBX}${name}`}>
                {name}
              </StyledMenuItem>
            ))}
            <ListSubheaderStyled sx={{color: !isDirectionTeamsToPbx ? activeColor : passiveColor}}>
              MS Teams
            </ListSubheaderStyled>
            {Object.values(MsPresenceStatusType).map((name) => (
              <StyledMenuItem disabled={isDirectionTeamsToPbx} key={name} value={`${DirectionMapPrefix.TEAMS}${name}`}>
                {name}
              </StyledMenuItem>
            ))}
            <ListSubheaderStyled sx={{color: activeColor}}>Other</ListSubheaderStyled>
            <StyledMenuItem
              key={SpecialPresenceStatusType.IGNORE}
              value={`${isDirectionTeamsToPbx ? DirectionMapPrefix.PBX : DirectionMapPrefix.TEAMS}${SpecialPresenceStatusType.IGNORE}`}>
              Ignore/Skip
            </StyledMenuItem>
          </Select>
        </FormControl>
      </RowBox>
      <RowBox>
        <IconButton onClick={() => onDelete(localPresenceMap.id)} sx={{width: '32px', height: '32px', mr: '7px'}}>
          <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
        </IconButton>
        {isAddButtonVisible && (
          <IconButton onClick={() => onAdd()} sx={{width: '32px', height: '32px', padding: '0'}}>
            <AddIcon fontSize="large" style={{width: '24px', height: '24px', color: activeColor}} />
          </IconButton>
        )}
      </RowBox>
    </Grid>
  );
}
