import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack} from '@mui/material';

import RemoveIcon20 from '../../../../base/icons/RemoveIcon20';
import {WidgetFormData} from '../../types';
import WidgetQuickReplyTextField from './WidgetQuickReplyTextField';

interface WidgetQuickReplyFieldsetProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
  position: number;
  onDelete: () => void;
}

export default function WidgetQuickReplyFieldset(props: WidgetQuickReplyFieldsetProps) {
  const {form, path, position, onDelete} = props;
  const {formState} = form;
  const {isSubmitting} = formState;

  return (
    <Box>
      <Stack mt={2} flexDirection="row" alignItems="flex-start">
        <Box sx={{width: '240px'}}>
          <WidgetQuickReplyTextField form={form} path={path} position={position} />
        </Box>
        <IconButton
          onClick={onDelete}
          disabled={isSubmitting}
          sx={{width: '32px', height: '32px', padding: '6px', marginTop: '4px', ml: 2}}>
          <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
        </IconButton>
      </Stack>
    </Box>
  );
}
