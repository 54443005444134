import getWidgetDomain from './getWidgetDomain';

export default function getWidgetInstallationCode(widgetId: string) {
  const domain = getWidgetDomain();

  return `<script>
    (function(d,t) {
      var BASE_URL='https://${domain}';
      var e=d.createElement(t),s=d.getElementsByTagName(t)[0];
      e.src=BASE_URL+'/kite/widget.js';
      e.defer=true;
      e.async=true;
      s.parentNode.insertBefore(e, s);
      e.onload=function(){
        window.wxSDK.run({
          kiteId: '${widgetId}',
          baseUrl: BASE_URL,
        });
      };
    })(document,'script');
</script>`;
}
