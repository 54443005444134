import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box} from '@mui/material';
import Button from '@mui/material/Button';

import {WidgetWelcomeMessage} from '@wildix/xbees-kite-client';

import {set} from 'lodash';

import {WidgetFormData} from '../../types';
import WidgetWelcomeMessageFieldset from './WidgetWelcomeMessageFieldset';

interface WidgetWelcomeMessagesFieldsetProps {
  form: UseFormReturn<WidgetFormData>;
  path: 'workHoursWelcomeMessages' | 'outOfWorkHoursWelcomeMessages';
}

export default function WidgetWelcomeMessagesFieldset(props: WidgetWelcomeMessagesFieldsetProps) {
  const {form, path} = props;
  const {formState, watch} = form;
  const {isSubmitting} = formState;
  const welcomeMessages = watch(path) || [];

  const onAddClick = () => {
    const values = structuredClone(form.getValues());

    const newWelcomeMessages: WidgetWelcomeMessage[] = [...welcomeMessages];
    newWelcomeMessages.push({
      text: '',
      delay: 0,
    });
    const newValues = set(values, path, newWelcomeMessages);

    form.reset(newValues);
  };

  const onDeleteClick = (index: number) => {
    if (welcomeMessages.length === 0) {
      return;
    }

    const values = structuredClone(form.getValues());

    const newWelcomeMessages = welcomeMessages.filter((_, messageIndex) => messageIndex !== index);
    const newValues = set(values, path, newWelcomeMessages);

    form.reset(newValues);
  };

  return (
    <Box>
      {welcomeMessages?.length > 0 && (
        <>
          {welcomeMessages.map((message, index) => (
            <WidgetWelcomeMessageFieldset
              key={`message_${index}`}
              form={form}
              path={`${path}.[${index}]`}
              position={index + 1}
              onDelete={() => onDeleteClick(index)}
            />
          ))}
        </>
      )}
      {(!welcomeMessages || welcomeMessages?.length < 10) && (
        <Box>
          <Button
            sx={{mt: welcomeMessages?.length > 0 ? 2 : 0}}
            onClick={onAddClick}
            disabled={isSubmitting}
            variant="outlined"
            size="small">
            Add message
          </Button>
        </Box>
      )}
    </Box>
  );
}
