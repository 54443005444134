import {Widget, WidgetAppearance, WidgetRoute, WidgetWelcomeMessage} from '@wildix/xbees-kite-client';
import {WidgetRouteRule} from '@wildix/xbees-kite-client/dist-types/models/models_0';

import {WidgetFormData} from '../types';
import getRandomRouteId from './getRandomRouteId';

export type WidgetOptions = Pick<Widget, 'name' | 'appearance' | 'target' | 'routes' | 'welcomeMessages'>;

function getWelcomeMessagesWithElements(
  welcomeMessages: WidgetWelcomeMessage[] | undefined,
  quickReplies: string[] | undefined,
) {
  if (!welcomeMessages?.length) {
    return undefined;
  }

  if (welcomeMessages?.length && quickReplies?.length) {
    const result = welcomeMessages.map(({elements, ...rest}) => ({...rest}) as WidgetWelcomeMessage);
    result[result.length - 1].elements = [
      {
        actions: quickReplies.map((text) => ({button: {text, handler: {reply: {}}}})),
      },
    ];

    return result;
  }

  return welcomeMessages;
}

function getRulesWithTimeZone(rules: WidgetRouteRule[], timeZone: string): WidgetRouteRule[] {
  return rules.map((rule) => {
    if (rule.timeFrame) {
      return {
        timeFrame: {
          ...rule.timeFrame,
          timeZone,
        },
      };
    }

    return rule;
  });
}

export default function getWidgetFromWidgetFormData(data: WidgetFormData): WidgetOptions {
  let routes: WidgetRoute[] | undefined;
  let isWorkHoursEnabled = false;

  // If the form says there are rules for work hours, we create a route
  if (data.workHoursRules && data.workHoursRules.length > 0) {
    routes = [
      {
        id: getRandomRouteId([]),
        target: data.workHoursTarget,
        rules: getRulesWithTimeZone(data.workHoursRules, data.workHoursRulesTimeZone!),
        welcomeMessages: getWelcomeMessagesWithElements(data.workHoursWelcomeMessages, data.workHoursQuickReplies),
      },
    ];
    isWorkHoursEnabled = true;
  }

  // For “out of work hours,” we store the fallback target & messages directly
  // on the WidgetOptions fields "target" and "welcomeMessages".
  const target = isWorkHoursEnabled ? data.outOfWorkHoursTarget! : data.workHoursTarget!;
  const welcomeMessages = isWorkHoursEnabled
    ? getWelcomeMessagesWithElements(data.outOfWorkHoursWelcomeMessages, data.outOfWorkHoursQuickReplies)
    : getWelcomeMessagesWithElements(data.workHoursWelcomeMessages, data.workHoursQuickReplies);

  let appearance: WidgetAppearance | undefined;

  if (data.appearance) {
    appearance = {};

    if (data.appearance.text) {
      appearance.text = {};

      if (data.appearance.text.title) {
        appearance.text.title = data.appearance.text.title;
      }

      if (data.appearance.text.topTitle) {
        appearance.text.topTitle = data.appearance.text.topTitle;
      }

      if (data.appearance.text.topSubtitle) {
        appearance.text.topSubtitle = data.appearance.text.topSubtitle;
      }

      if (data.appearance.text.button) {
        appearance.text.button = data.appearance.text.button;
      }
    }

    if (data.appearance.pallete) {
      appearance.pallete = {};

      if (data.appearance.pallete.backgroundColor) {
        appearance.pallete.backgroundColor = data.appearance.pallete.backgroundColor;
      }

      if (data.appearance.pallete.accentColor) {
        appearance.pallete.accentColor = data.appearance.pallete.accentColor;
      }
    }
  }

  return {
    name: data.name!,
    appearance,
    target,
    routes,
    welcomeMessages,
  };
}
