import {ENV} from '../../../env';

export default function getWidgetDomain() {
  switch (ENV) {
    case 'stage':
      return 'stage.app.x-bees.com';
    case 'stable':
      return 'stable.app.x-bees.com';
    default:
      return 'app.x-bees.com';
  }
}
