import React from 'react';

import {Stack} from '@mui/material';

import {Integration} from '../../../base/types';
import MicrosoftPresenceIntegrationSettingsMaps from './maps/MicrosoftPresenceIntegrationSettingsMaps';
import MicrosoftPresenceIntegrationSettingsItem from './MicrosoftPresenceIntegrationSettingsItem';
import MicrosoftPresenceIntegrationUsersSettings from './MicrosoftPresenceIntegrationUsersSettings';

type props = {
  integration: Integration;
};

export default function MicrosoftPresenceIntegrationSettings({integration}: props) {
  return (
    <Stack sx={{mt: 5, width: 840}} spacing={2} flexDirection="column">
      <MicrosoftPresenceIntegrationSettingsItem
        title="Synchronization settings"
        subtitle="Specify the users to whom this integration will apply.">
        <MicrosoftPresenceIntegrationUsersSettings integration={integration} />
      </MicrosoftPresenceIntegrationSettingsItem>
      <MicrosoftPresenceIntegrationSettingsItem
        title="Presence Mapping"
        subtitle="The presence sync feature allows you to manage and transfer status between Microsoft Teams
and PBX">
        <MicrosoftPresenceIntegrationSettingsMaps integration={integration} />
      </MicrosoftPresenceIntegrationSettingsItem>
    </Stack>
  );
}
