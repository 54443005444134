import {WidgetRoute} from '@wildix/xbees-kite-client';

function generateUniqueId(): string {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'; // Alphanumeric lowercase characters
  let id = '';

  for (let charIndex = 0; charIndex < 4; charIndex++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return id;
}

export default function getRandomRouteId(existingRoutes: WidgetRoute[]): string {
  const existingIds = new Set(existingRoutes.map((route) => route.id));
  let newId = generateUniqueId();

  // Ensure the generated ID is unique
  while (existingIds.has(newId)) {
    newId = generateUniqueId();
  }

  return newId;
}
