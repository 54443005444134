import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AccordionProps, Box, styled} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const StyledAccordionSummary = styled(AccordionSummary)({
  fontSize: '16px',
  padding: '18px 24px',
  alignItems: 'flex-start',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
});

const StyledAccordion = styled(Accordion)({
  border: '1px solid rgba(174, 175, 177, 1)',

  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',

  '&:first-of-type': {
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  '&:nth-of-type(1)': {
    borderTopWidth: '1px',
  },
  '&.Mui-expanded': {
    borderTopWidth: '1px',
    borderRadius: '16px',
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0 24px 24px',
});

interface SettingsAccordionProps extends Omit<AccordionProps, 'children'> {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

export default function SettingsAccordion(props: SettingsAccordionProps) {
  const {title, subtitle, children, ...accordionProps} = props;

  return (
    <StyledAccordion sx={{mt: 2}} elevation={0} variant="outlined" {...accordionProps}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{fontWeight: '700', fontSize: '16px', lineHeight: '24px'}}>{title}</Box>
        {subtitle && <Box sx={{fontSize: '13px', lineHeight: '24px', marginTop: '6px'}}>{subtitle}</Box>}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}
