import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack} from '@mui/material';

import RemoveIcon20 from '../../../../base/icons/RemoveIcon20';
import {WidgetFormData} from '../../types';
import WidgetWelcomeMessageDelayField from './WidgetWelcomeMessageDelayField';
import WidgetWelcomeMessageTextField from './WidgetWelcomeMessageTextField';

interface WidgetWelcomeMessageFieldsetProps {
  form: UseFormReturn<WidgetFormData>;
  position: number;
  path: string;
  onDelete: () => void;
}

export default function WidgetWelcomeMessageFieldset(props: WidgetWelcomeMessageFieldsetProps) {
  const {form, position, path, onDelete} = props;
  const {formState} = form;
  const {isSubmitting} = formState;

  return (
    <Box>
      <Stack mt={position > 1 ? 2 : 0} flexDirection="row" alignItems="flex-start">
        <Box sx={{width: '400px'}}>
          <WidgetWelcomeMessageTextField form={form} path={`${path}.text`} position={position} />
        </Box>
        <Box sx={{width: '100px', marginLeft: '24px'}}>
          <WidgetWelcomeMessageDelayField form={form} path={`${path}.delay`} />
        </Box>
        <IconButton
          onClick={onDelete}
          disabled={isSubmitting}
          sx={{width: '32px', height: '32px', padding: '6px', marginTop: '4px', ml: 2}}>
          <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
        </IconButton>
      </Stack>
    </Box>
  );
}
