import React, {useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box} from '@mui/material';
import Button from '@mui/material/Button';

import {set} from 'lodash';

import {WidgetFormData} from '../../types';
import WidgetQuickReplyFieldset from './WidgetQuickReplyFieldset';

interface WidgetQuickRepliesFieldsetProps {
  form: UseFormReturn<WidgetFormData>;
  path: 'workHoursQuickReplies' | 'outOfWorkHoursQuickReplies';
  messagesPath: 'workHoursWelcomeMessages' | 'outOfWorkHoursWelcomeMessages';
}

const EMPTY_REPLIES: string[] = [];

export default function WidgetQuickRepliesFieldset(props: WidgetQuickRepliesFieldsetProps) {
  const {form, path, messagesPath} = props;
  const {formState, watch, setValue} = form;
  const {isSubmitting} = formState;
  const quickReplies = watch(path) || EMPTY_REPLIES;
  const welcomeMessages = watch(messagesPath) || [];

  const onAddClick = () => {
    const values = structuredClone(form.getValues());

    const newQuickReplies: string[] = [...quickReplies, ''];
    const newValues = set(values, path, newQuickReplies);

    form.reset(newValues);
  };

  const onDeleteClick = (index: number) => {
    if (quickReplies.length === 0) {
      return;
    }

    const values = structuredClone(form.getValues());

    const newQuickReplies = quickReplies.filter((_, replyIndex) => replyIndex !== index);
    const newValues = set(values, path, newQuickReplies);

    form.reset(newValues);
  };

  const quickRepliesId = useMemo(() => {
    if (quickReplies || path) {
      return String(Math.random());
    }
  }, [quickReplies, path]);

  return (
    <Box>
      {quickReplies?.length > 0 && welcomeMessages?.length > 0 && (
        <>
          {quickReplies.map((text, index) => (
            <WidgetQuickReplyFieldset
              key={`${quickRepliesId}_${index}`}
              form={form}
              path={`${path}.[${index}]`}
              position={index + 1}
              onDelete={() => onDeleteClick(index)}
            />
          ))}
        </>
      )}
      <Box>
        <Button
          sx={{mt: 2}}
          onClick={onAddClick}
          disabled={isSubmitting || welcomeMessages.length === 0}
          variant="outlined"
          size="small">
          Add quick reply
        </Button>
      </Box>
    </Box>
  );
}
