import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {WidgetFormData} from '../../types';

interface WidgetNameFieldProps {
  form: UseFormReturn<WidgetFormData>;
}

export default function WidgetNameField(props: WidgetNameFieldProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = errors?.name;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          fullWidth
          label="Name"
          placeholder="Name"
          required
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="name"
      rules={{
        required: 'This field is required.',
        // TODO: Review limits here.
        minLength: {
          value: 2,
          message: 'Name must be at least 2 characters long.',
        },
        maxLength: {
          value: 50,
          message: 'Name must be no more than 50 characters long.',
        },
      }}
    />
  );
}
