export default function getWorkHoursTimeSlots(isStart = true) {
  const timeSlots = [];
  const start = isStart ? 0 : 15; // Start at 00:00 or 00:15
  const end = isStart ? 24 * 60 - 15 : 24 * 60; // End at 23:45 or 24:00

  for (let i = start; i <= end; i += 15) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    const timeLabel = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    timeSlots.push({id: i, label: timeLabel});
  }

  return timeSlots;
}
