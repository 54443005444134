import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';

import {UpdateWidgetCommand} from '@wildix/xbees-kite-client';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';
import {KITE_TITLE} from '../../../texts';
import {KitePath} from '../constants';
import getWidgetFormDataFromWidget from '../helpers/getWidgetFormDataFromWidget';
import getWidgetFromWidgetFormData from '../helpers/getWidgetFromWidgetFormData';
import useGetKiteWidgetQuery from '../hooks/useGetKiteWidgetQuery';
import {WidgetFormData} from '../types';
import WidgetForm from './WidgetForm';

interface WidgetEditScreenParams extends Record<string, string> {
  widgetId: string;
}

export default function WidgetEditScreen() {
  const params = useParams<WidgetEditScreenParams>();
  const navigate = useNavigate();
  const form = useForm<WidgetFormData>({});
  const {setError} = form;
  const [initialized, setInitialized] = useState(false);

  const queryClient = useQueryClient();
  const queryWidgetInfo = useGetKiteWidgetQuery(params.widgetId);

  useEffect(() => {
    if (queryWidgetInfo.data && !initialized) {
      const {widget} = queryWidgetInfo.data;

      form.reset(getWidgetFormDataFromWidget(widget));
      setInitialized(true);
    }
  }, [queryWidgetInfo.data]);

  const onSubmit = useCallback(
    async (data: WidgetFormData) => {
      const client = getXbsKiteClient();
      const options = getWidgetFromWidgetFormData(data);

      try {
        await client.send(
          new UpdateWidgetCommand({
            widgetId: params.widgetId!,
            ...options,
          }),
        );

        queryClient.resetQueries('kite/widgets');

        navigate(KitePath.base);
      } catch (error) {
        // @ts-ignore
        setError('name', {message: error.toString()});
      }
    },
    [navigate],
  );

  if (queryWidgetInfo.isError) {
    return <ApiErrorBox error={queryWidgetInfo.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={KitePath.base}>
          {KITE_TITLE}
        </Typography>
        <Typography color="text.primary">
          {queryWidgetInfo.data ? queryWidgetInfo.data.widget.name : <CircularProgress size={12} />}
        </Typography>
      </Breadcrumbs>
      {initialized ? (
        <WidgetForm
          header={
            <Typography color="text.primary" variant="h5">
              {queryWidgetInfo.data ? queryWidgetInfo.data.widget.name : <CircularProgress size={12} />}
            </Typography>
          }
          form={form}
          onSubmit={onSubmit}
        />
      ) : null}
    </Box>
  );
}
