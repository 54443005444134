import React, {useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, IconButton, Stack} from '@mui/material';

import {Widget} from '@wildix/xbees-kite-client';

import IntegrationDeleteDialog from '../../../../base/components/IntegrationDeleteDialog';
import formatDateString from '../../../../base/helpers/formatDateString';
import PencilIcon20 from '../../../../base/icons/PencilIcon20';
import RemoveIcon20 from '../../../../base/icons/RemoveIcon20';
import {KitePath} from '../../constants';
import useDeleteKiteWidgetMutation from '../../hooks/useDeleteKiteWidgetMutation';

interface WidgetListItemProps {
  widget: Widget;
  isSelected?: boolean;
  onClick?: () => void;
}

export default function WidgetListItem(props: WidgetListItemProps) {
  const {widget, isSelected, onClick} = props;
  const navigate = useNavigate();

  const onEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${KitePath.base}/widgets/${widget.id}`);
  };

  const {mutate: deleteMutate, isLoading: isDeleteLoading} = useDeleteKiteWidgetMutation();

  const removeDialogRef = useRef<any>();
  const onDeleteClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    removeDialogRef.current.open();
  }, []);

  const onDeleteConfirmClick = useCallback(() => {
    if (widget) {
      deleteMutate(widget.id);
    }
  }, [widget, deleteMutate]);

  const subtitle =
    widget.updatedAt && widget.updatedAt !== widget.createdAt
      ? `Edited at: ${formatDateString(widget.updatedAt)}`
      : `Created at: ${formatDateString(widget.createdAt)}`;

  return (
    <Box mt={2}>
      <Stack
        onClick={onClick}
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          padding: '12px 12px 12px 18px',
          border: `1px solid ${isSelected ? 'rgba(22, 123, 200, 1)' : 'rgba(152, 165, 174, 0.25)'}`,
          borderRadius: '10px',
          boxShadow: isSelected ? '0px 3px 8px rgba(38, 41, 43, 0.12)' : undefined,
          '&:hover': {
            boxShadow: '0px 3px 8px rgba(38, 41, 43, 0.12)',
          },
        }}>
        <Box flex="1" sx={{paddingRight: '12px'}}>
          <Box sx={{fontSize: '16px', fontWeight: '500', lineHeight: '24px'}}>{widget.name}</Box>
          <Box sx={{fontSize: '12px', lineHeight: '18px', color: 'rgba(139, 143, 156, 1)'}}>{subtitle}</Box>
        </Box>
        <Stack display="flex" flexDirection="row" gap="8px">
          <IconButton
            onClick={onEditClick}
            disabled={isDeleteLoading}
            sx={{width: '32px', height: '32px', padding: '6px'}}>
            <PencilIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
          <IconButton
            onClick={onDeleteClick}
            disabled={isDeleteLoading}
            sx={{width: '32px', height: '32px', padding: '6px'}}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Stack>
      </Stack>
      <IntegrationDeleteDialog
        apiRef={removeDialogRef}
        title={`Widget #${widget.id}`}
        onDeleteClick={onDeleteConfirmClick}
      />
    </Box>
  );
}
