import React, {useCallback, useMemo, useState} from 'react';

import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import {Button, Grid} from '@mui/material';

import {generateId} from '../../../../base/helpers/generateId';
import useConsoleIntegrationPatchMutation from '../../../hooks/useConsoleIntegrationPatchMutation';
import {DirectionMap, DirectionMapPrefix, MsPresenceIntegrationSettingsMapsProps, PresenceMap} from '../../types';
import MicrosoftPresenceIntegrationSettingsMapsItem from './MicrosoftPresenceIntegrationSettingsMapsItem';

export default function MicrosoftPresenceIntegrationSettingsMaps(props: MsPresenceIntegrationSettingsMapsProps) {
  const {
    integration: {integrationId, data},
  } = props;

  const presenceMaps = data.settings?.mapping
    ? data.settings.mapping.map((map: PresenceMap) => {
        const {from, to, direction} = map;
        const isTeamsMap = direction === DirectionMap.TEAMS_TO_PBX;

        return {
          ...map,
          from: isTeamsMap ? `${DirectionMapPrefix.TEAMS}${from}` : `${DirectionMapPrefix.PBX}${from}`,
          to: isTeamsMap ? `${DirectionMapPrefix.PBX}${to}` : `${DirectionMapPrefix.TEAMS}${to}`,
        };
      })
    : [];

  const [mapping, setMapping] = useState<PresenceMap[]>(() => presenceMaps);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const regExpCleaner = useMemo(() => new RegExp(`^(${DirectionMapPrefix.TEAMS}|${DirectionMapPrefix.PBX})`), []);

  const onSuccess = () => {
    setIsSaveButtonDisabled(true);
  };

  const filterEmptyPresenceMap = useCallback(
    (maps: PresenceMap[]) => maps.filter((map: PresenceMap) => !(map.from === '' || map.to === '')),
    [],
  );

  const transformMapping = useCallback(
    (preparedMapping: PresenceMap[]) =>
      preparedMapping.map((map) => {
        const {from, to} = map;

        return {
          ...map,
          from: from.replace(regExpCleaner, ''),
          to: to.replace(regExpCleaner, ''),
        };
      }),
    [regExpCleaner],
  );

  const {isLoading, mutate} = useConsoleIntegrationPatchMutation({onSuccess}, true);

  const onSave = useCallback(
    (event: boolean | unknown) => {
      const isLastElementDeleted = typeof event == 'boolean' && event;
      const preparedMapping = filterEmptyPresenceMap(mapping);

      mutate({
        integrationId,
        data: {
          ...data,
          settings: {...data.settings, mapping: isLastElementDeleted ? [] : transformMapping(preparedMapping)},
        },
      });

      return true;
    },
    [mapping, filterEmptyPresenceMap, mutate, integrationId, transformMapping, data],
  );

  const onAdd = useCallback(() => {
    setMapping([...mapping, {from: '', to: '', id: generateId()}]);
  }, [mapping]);

  const onDelete = useCallback(
    (targetId: string) => {
      setMapping(() => mapping.filter(({id}) => id !== targetId));
      setIsSaveButtonDisabled(false);

      if (mapping.length === 1) {
        onSave(true);
      }
    },
    [mapping, onSave],
  );

  const setPresenceMap = useCallback(
    (presenceMap: PresenceMap) => {
      setIsSaveButtonDisabled(false);

      const filteredMapping = filterEmptyPresenceMap(mapping);
      const index = filteredMapping.findIndex((map) => map.id === presenceMap.id);

      if (index !== -1) {
        filteredMapping[index] = presenceMap;
      } else {
        filteredMapping.push(presenceMap);
      }

      setMapping(() => filteredMapping);
    },
    [mapping, filterEmptyPresenceMap],
  );

  return (
    <Grid container sx={{m: 0}}>
      {mapping.length > 0 &&
        mapping.map((map: PresenceMap, index: number) => (
          <MicrosoftPresenceIntegrationSettingsMapsItem
            key={map.id}
            presenceMap={{...map}}
            mapping={mapping}
            onAdd={onAdd}
            onDelete={onDelete}
            setPresenceMap={setPresenceMap}
            isAddButtonVisible={mapping.length === index + 1}
          />
        ))}
      {mapping.length > 0 && (
        <Grid item xs={12} sx={{mt: 1}}>
          <LoadingButton
            sx={{background: '#1976d2'}}
            loading={isLoading}
            disabled={isSaveButtonDisabled}
            variant="contained"
            type="submit"
            size="medium"
            onClick={onSave}>
            Save
          </LoadingButton>
        </Grid>
      )}
      {!mapping.length && (
        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            startIcon={<AddIcon fontSize="large" />}
            variant="outlined"
            sx={{textTransform: 'none'}}
            onClick={onAdd}>
            Add mapping
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
