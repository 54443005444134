import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {CircularProgress, ListSubheader, MenuItem} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import {get, groupBy} from 'lodash';

import useListKiteServicesQuery from '../../hooks/useListKiteServicesQuery';
import {WidgetFormData} from '../../types';

interface WidgetTargetServiceFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: 'workHoursTarget.service' | 'outOfWorkHoursTarget.service';
}

export default function WidgetTargetServiceField(props: WidgetTargetServiceFieldProps) {
  const {form, path} = props;
  const {control, formState, setValue} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path);

  const servicesQuery = useListKiteServicesQuery();
  const services = servicesQuery.data?.services || [];

  const {isLoading} = servicesQuery;

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  const servicesGroupedByPbx = groupBy(services, (service) => service.pbx.domain);
  const servicesPbxes = Object.keys(servicesGroupedByPbx);

  let options: React.ReactNode[];

  if (servicesPbxes.length > 1) {
    options = servicesPbxes.flatMap((pbxDomain) => [
      <ListSubheader key={pbxDomain}>{pbxDomain}</ListSubheader>,
      servicesGroupedByPbx[pbxDomain]
        .sort((serviceA, serviceB) => serviceA.title.localeCompare(serviceB.title))
        .map((service) => (
          <MenuItem key={service.uri} value={service.uri}>
            {service.title}
          </MenuItem>
        )),
    ]);
  } else {
    options = services.map((service) => (
      <MenuItem key={service.uri} value={service.uri}>
        {service.title}
      </MenuItem>
    ));
  }

  return (
    <Controller
      control={control}
      name={`${path}.uri`}
      rules={{required: true}}
      render={({field: {onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" error={!!error} disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Service</InputLabel>
          <Select
            disabled={services.length === 0 || isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value || ''}
            label="Service"
            onChange={(event) => {
              const uri = event.target.value;
              const service = services.find((item) => item.uri === uri);

              if (service) {
                setValue(path, {id: service.id, uri: service.uri});
              }
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {options}
          </Select>
        </FormControl>
      )}
    />
  );
}
