import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {WidgetFormData} from '../../types';

interface WidgetWelcomeMessageDelayFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
}

interface DayDescription {
  id: number | undefined;
  label: string;
}

const OPTIONS: DayDescription[] = [
  {id: 0, label: '-'},
  {id: 1, label: '1 sec'},
  {id: 2, label: '2 sec'},
  {id: 3, label: '3 sec'},
  {id: 4, label: '4 sec'},
  {id: 5, label: '5 sec'},
];

export default function WidgetWelcomeMessageDelayField(props: WidgetWelcomeMessageDelayFieldProps) {
  const {form, path} = props;
  const {control, formState, trigger, watch} = form;
  const {isSubmitting} = formState;

  return (
    <Controller
      control={control}
      name={path as any}
      rules={{required: true}}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Show after</InputLabel>
          <Select
            disabled={isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value}
            label="Show after"
            onChange={(event) => {
              onChange(event);
              trigger(name);
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {OPTIONS.map(({id, label}) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
