import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';

import useListKiteBotsQuery from '../../hooks/useListKiteBotsQuery';
import useListKiteServicesQuery from '../../hooks/useListKiteServicesQuery';
import {WidgetFormData} from '../../types';
import WidgetTargetAgentField from './WidgetTargetAgentField';
import WidgetTargetBotField from './WidgetTargetBotField';
import WidgetTargetServiceField from './WidgetTargetServiceField';

interface WidgetTargetFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: 'workHoursTarget' | 'outOfWorkHoursTarget';
}

export default function WidgetTargetField(props: WidgetTargetFieldProps) {
  const {form, path} = props;
  const {formState, watch, setValue} = form;
  const {isSubmitting} = formState;

  const servicesQuery = useListKiteServicesQuery();
  const botsQuery = useListKiteBotsQuery();

  const target = watch(path);
  let targetType = 'agent' as 'service' | 'agent' | 'bot';

  if (target?.service) {
    targetType = 'service';
  } else if (target?.bot) {
    targetType = 'bot';
  }

  const onTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target as HTMLInputElement;

    switch (value) {
      case 'agent':
        setValue(path, {agent: {email: ''}});
        break;
      case 'service':
        setValue(path, {service: {id: 0, uri: ''}});
        break;
      case 'bot':
        setValue(path, {bot: {id: ''}});
        break;
    }
  };

  const isServicesTargetDisabled = !servicesQuery.data?.services?.length;
  const isBotsTargetDisabled = !botsQuery.data?.bots?.length;

  return (
    <Box sx={{mt: -1}}>
      <FormControl>
        <RadioGroup value={targetType} onChange={onTargetChange} sx={{flexDirection: 'row'}}>
          <FormControlLabel value="agent" control={<Radio disabled={isSubmitting} />} label="An agent" />
          <FormControlLabel
            value="service"
            sx={{marginLeft: '8px'}}
            control={<Radio disabled={isSubmitting || isServicesTargetDisabled} />}
            label="A group of agents"
          />
          <FormControlLabel
            value="bot"
            sx={{marginLeft: '8px'}}
            control={<Radio disabled={isSubmitting || isBotsTargetDisabled} />}
            label="Bot"
          />
        </RadioGroup>
      </FormControl>
      <Box mt={2} sx={{width: '60%'}}>
        {targetType === 'agent' && <WidgetTargetAgentField form={form} path={`${path}.agent.email`} />}
        {targetType === 'service' && <WidgetTargetServiceField form={form} path={`${path}.service`} />}
        {targetType === 'bot' && <WidgetTargetBotField form={form} path={`${path}.bot.id`} />}
      </Box>
    </Box>
  );
}
