import {useQuery} from 'react-query';

import {ListWidgetsCommand} from '@wildix/xbees-kite-client';

import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';

export default function useListKiteWidgetsQuery() {
  return useQuery('kite/widgets', () => getXbsKiteClient().send(new ListWidgetsCommand({})), {
    retry: false,
  });
}
