import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack} from '@mui/material';

import {WidgetRouteTimeFrameRule} from '@wildix/xbees-kite-client/dist-types/models/models_0';

import RemoveIcon20 from '../../../../base/icons/RemoveIcon20';
import {WidgetFormData} from '../../types';
import WidgetWorkHoursTimeFrameDayField from './WidgetWorkHoursTimeFrameDayField';
import WidgetWorkHoursTimeFrameEndField from './WidgetWorkHoursTimeFrameEndField';
import WidgetWorkHoursTimeFrameStartField from './WidgetWorkHoursTimeFrameStartField';

interface WidgetWorkHoursTimeFrameRuleFieldsetProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
  onDelete: () => void;
}

export default function WidgetWorkHoursTimeFrameRuleFieldset(props: WidgetWorkHoursTimeFrameRuleFieldsetProps) {
  const {form, path, onDelete} = props;
  const {formState, watch} = form;
  const {isSubmitting} = formState;

  const timeFrame = watch(path as any) as WidgetRouteTimeFrameRule;
  const {startMinute, endMinute} = timeFrame;

  return (
    <Stack mt={2} flexDirection="row" alignItems="center">
      <Box sx={{width: '160px'}}>
        <WidgetWorkHoursTimeFrameDayField form={form} path={`${path}.day`} />
      </Box>
      <Stack flexDirection="row" alignItems="center" gap="14px" ml={4}>
        <Box sx={{width: '110px'}}>
          <WidgetWorkHoursTimeFrameStartField form={form} till={endMinute} path={`${path}.startMinute`} />
        </Box>
        <Box sx={{fontSize: '14px', color: 'rgba(117, 118, 121, 1)'}}>to</Box>
        <Box sx={{width: '110px'}}>
          <WidgetWorkHoursTimeFrameEndField form={form} from={startMinute} path={`${path}.endMinute`} />
        </Box>
        <IconButton onClick={onDelete} disabled={isSubmitting} sx={{width: '32px', height: '32px', padding: '6px'}}>
          <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
        </IconButton>
      </Stack>
    </Stack>
  );
}
