import {useMutation, useQueryClient} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';

import {DeleteWidgetCommand, DeleteWidgetOutput} from '@wildix/xbees-kite-client';

import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';

export default function useDeleteKiteWidgetMutation(
  options: UseMutationOptions<DeleteWidgetOutput, unknown, string> = {},
) {
  const qc = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (widgetId: string) => getXbsKiteClient().send(new DeleteWidgetCommand({widgetId})),
    onSuccess: (...args) => {
      if (options?.onSuccess) {
        options.onSuccess(...args);
      }

      qc.resetQueries('kite/widgets', {exact: true});
    },
  });
}
