import {Widget, WidgetWelcomeMessage} from '@wildix/xbees-kite-client';
import {WidgetRouteRule} from '@wildix/xbees-kite-client/dist-types/models/models_0';

import {WidgetFormData} from '../types';
import getTimeZoneInEtcFormat from './getTimeZoneInEtcFormat';

function getQuickReplies(welcomeMessages: WidgetWelcomeMessage[] | undefined): string[] | undefined {
  const result: string[] = [];

  if (welcomeMessages && welcomeMessages.length > 0) {
    const last = welcomeMessages[welcomeMessages.length - 1];

    if (last.elements && last.elements.length > 0) {
      const element = last.elements[0];

      if (element.actions && element.actions.length > 0) {
        const {actions} = element;

        for (const action of actions) {
          if (action.button && action.button.handler.reply) {
            result.push(action.button.text);
          }
        }
      }
    }
  }

  if (result.length > 0) {
    return result;
  }

  return undefined;
}

function getTimeZone(rules: WidgetRouteRule[] | undefined) {
  if (rules && rules.length > 0) {
    const rule = rules[0];

    if (rule.timeFrame?.timeZone) {
      return rule.timeFrame.timeZone;
    }
  }

  return getTimeZoneInEtcFormat();
}

export default function getWidgetFormDataFromWidget(widget: Widget): WidgetFormData {
  const {id, name, routes, welcomeMessages, appearance, target} = widget;

  if (routes && routes.length > 0) {
    const route = routes[0];

    return {
      id,
      name,
      appearance,

      workHoursTarget: route.target,
      workHoursRules: route.rules,
      workHoursRulesTimeZone: getTimeZone(route.rules),
      workHoursWelcomeMessages: route.welcomeMessages,
      workHoursQuickReplies: getQuickReplies(route.welcomeMessages),

      outOfWorkHoursTarget: target,
      outOfWorkHoursWelcomeMessages: welcomeMessages,
      outOfWorkHoursQuickReplies: getQuickReplies(welcomeMessages),
    };
  } else {
    return {
      id,
      name,
      appearance,

      workHoursTarget: target,
      workHoursWelcomeMessages: welcomeMessages,
      workHoursRulesTimeZone: getTimeZone(undefined),
      workHoursQuickReplies: getQuickReplies(welcomeMessages),
    };
  }
}
