import React, {useCallback, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import {Widget} from '@wildix/xbees-kite-client';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import KiteIcon from '../../../base/icons/KiteIcon';
import {KITE_DESCRIPTION, KITE_TITLE} from '../../../texts';
import {KitePath} from '../constants';
import getWidgetFormDataFromWidget from '../helpers/getWidgetFormDataFromWidget';
import useListKiteWidgetsQuery from '../hooks/useListKiteWidgetsQuery';
import {WidgetFormData} from '../types';
import KitePreviewFrame from './KitePreviewFrame';
import WidgetListItem from './list/WidgetListItem';

export default function KiteScreen() {
  const navigate = useNavigate();
  const query = useListKiteWidgetsQuery();
  const [selectedWidget, setSelectedWidget] = useState<WidgetFormData>();

  const onAddClick = useCallback(() => {
    navigate(KitePath.add);
  }, [navigate]);

  const handleWidgetClick = useCallback(
    (widget: Widget) => {
      if (selectedWidget?.id === widget.id) {
        setSelectedWidget(undefined);
      } else {
        setSelectedWidget(getWidgetFormDataFromWidget(widget));
      }
    },
    [selectedWidget],
  );

  if (query.isError) {
    return <ApiErrorBox error={query.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary">{KITE_TITLE}</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4}}>
        <Box sx={{width: '842px'}}>
          <Box display="flex">
            <Box width="96px">
              <KiteIcon width={96} height={96} />
            </Box>
            <Box flex={1} ml={5} pb={8}>
              <Typography color="text.primary" variant="h5">
                Kite
              </Typography>
              <Typography color="text.primary" sx={{paddingTop: 2, width: '712px'}}>
                {KITE_DESCRIPTION}
              </Typography>
              <Button sx={{mt: 2}} onClick={onAddClick} disabled={false} variant="contained" size="medium">
                Add new widget
              </Button>
              <Box mt={4}>
                <Typography color="text.primary" sx={{fontSize: '24px', fontWeight: '500'}}>
                  Widgets
                </Typography>
              </Box>
              <Box flex={1} mt={2} sx={{display: 'flex', flexDirection: 'row'}}>
                <Box
                  sx={{
                    width: selectedWidget ? '580px' : '712px',
                    transition: 'width 0.3s ease-in-out',
                  }}>
                  {query.isLoading && <CircularProgress size={24} />}
                  {query.isSuccess &&
                    query.data.widgets.map((widget) => (
                      <WidgetListItem
                        key={widget.id}
                        widget={widget}
                        isSelected={selectedWidget?.id === widget.id}
                        onClick={() => handleWidgetClick(widget)}
                      />
                    ))}
                </Box>
                {selectedWidget && (
                  <Box sx={{width: '400px', position: 'relative', marginLeft: '42px', mt: 2}}>
                    <Box sx={{position: 'sticky', top: '16px'}}>
                      <KitePreviewFrame key={selectedWidget.id} widget={selectedWidget} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
