import {Integration, IntegrationService} from '../../base/types';

export interface MicrosoftPresenceInstallProps {
  service: IntegrationService.MICROSOFT_PRESENCE;
  data: {
    code: string;
    ignoredEmails?: string[];
  };
}

export interface Settings {
  excludedEmails?: string[];
  includeEmails?: string[];
}

export interface MicrosoftPresenceUpdateProps {
  integrationId: string;
  status?: 'enabled' | 'disabled';
  data?: {
    settings: Settings;
  };
}

export enum MicrosoftPresenceIntegrationPath {
  base = '/presence',
  callback = '/presence/callback',
}

export enum ProcessingUsersType {
  ALL = 'all',
  EXCLUDE = 'exclude',
  INCLUDE = 'include',
}

export enum MsPresenceStatusType {
  AVAILABLE = 'Available',
  BUSY = 'Busy',
  DO_NOT_DISTURB = 'DoNotDisturb',
  BE_RIGHT_BACK = 'BeRightBack',
  AWAY = 'Away',
  OFFLINE = 'Offline',
  IN_A_CALL = 'InACall',
  IN_A_CONFERENCE_CALL = 'InAConferenceCall',
}

export enum PbxPresenceStatusType {
  ONLINE = 'online',
  AWAY = 'away',
  DND = 'dnd',
  OFFLINE = 'offline',
  CUSTOM_DND_IN_A_CALL = 'dnd:InACall',
  CUSTOM_DND_IN_A_CONFERENCE = 'dnd:InAConference',
  CUSTOM_DND_DO_NOT_DISTURB = 'dnd:DoNotDisturb',
}

export enum SpecialPresenceStatusType {
  IGNORE = 'Ignore',
}

export enum DirectionMap {
  TEAMS_TO_PBX = 'TEAMS_TO_PBX',
  PBX_TO_TEAMS = 'PBX_TO_TEAMS',
}

export enum DirectionMapPrefix {
  TEAMS = 'MS Teams: ',
  PBX = 'PBX: ',
}

export interface PresenceMap {
  id: string;
  direction?: DirectionMap;
  from: string;
  to: string;
}

export interface MsPresenceIntegrationSettingsMapsProps {
  integration: Integration;
}
export interface MsPresenceSettingsMapsItemProps {
  key: string;
  presenceMap: PresenceMap;
  mapping: PresenceMap[];
  isAddButtonVisible: boolean;
  setPresenceMap: (presenceMap: PresenceMap) => void;
  onDelete: (key: string) => void;
  onAdd: () => void;
}
