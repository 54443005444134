import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {InputAdornment, TextField} from '@mui/material';

import {get} from 'lodash';

import {WidgetFormData} from '../../types';

interface WidgetQuickReplyTextFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
  position: number;
}

export default function WidgetQuickReplyTextField(props: WidgetQuickReplyTextFieldProps) {
  const {form, path, position} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path as any);

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          label="Text"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{position}.</InputAdornment>,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name={path as any}
      rules={{
        required: 'This field is required.',
        minLength: {
          value: 3,
          message: 'Quick Reply must be at least 3 characters long.',
        },
        maxLength: {
          value: 96,
          message: 'Quick Reply must be no more than 96 characters long.',
        },
      }}
    />
  );
}
