import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {InputAdornment, TextField} from '@mui/material';

import {get} from 'lodash';

import {WidgetFormData} from '../../types';

interface WidgetWelcomeMessageTextFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
  position: number;
}

export default function WidgetWelcomeMessageTextField(props: WidgetWelcomeMessageTextFieldProps) {
  const {form, path, position} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path as any);

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          label="Message"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{position}.</InputAdornment>,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name={path as any}
      rules={{
        required: 'This field is required.',
        minLength: {
          value: 2,
          message: 'Message must be at least 1 characters long.',
        },
        maxLength: {
          value: 1000,
          message: 'Message must be no more than 1000 characters long.',
        },
      }}
    />
  );
}
