import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {CircularProgress} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {get} from 'lodash';

import useListKiteBotsQuery from '../../hooks/useListKiteBotsQuery';
import {WidgetFormData} from '../../types';

interface WidgetTargetBotFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: 'workHoursTarget.bot.id' | 'outOfWorkHoursTarget.bot.id';
}

export default function WidgetTargetBotField(props: WidgetTargetBotFieldProps) {
  const {form, path} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path);

  const botsQuery = useListKiteBotsQuery();
  const bots = botsQuery.data?.bots || [];

  const {isLoading} = botsQuery;

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  return (
    <Controller
      control={control}
      name={path as any}
      rules={{required: true}}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" error={!!error} disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Bot</InputLabel>
          <Select
            disabled={bots.length === 0 || isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value || ''}
            label="Bot"
            onChange={(event) => {
              onChange(event);
              trigger(name);
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {bots.map((bot) => (
              <MenuItem key={bot.id} value={bot.id}>
                {bot.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
