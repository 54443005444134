import React, {useCallback, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, Typography} from '@mui/material';

import {CreateWidgetCommand} from '@wildix/xbees-kite-client';

import getXbsKiteClient from '../../../base/helpers/getXbsKiteClient';
import {KITE_TITLE} from '../../../texts';
import {KitePath} from '../constants';
import getTimeZoneInEtcFormat from '../helpers/getTimeZoneInEtcFormat';
import getWidgetFromWidgetFormData from '../helpers/getWidgetFromWidgetFormData';
import {WidgetFormData} from '../types';
import WidgetForm from './WidgetForm';

export default function WidgetAddScreen() {
  const navigate = useNavigate();
  const timeZone = useMemo(() => getTimeZoneInEtcFormat(), []);

  const form = useForm<WidgetFormData>({
    defaultValues: {
      name: '',
      workHoursRulesTimeZone: timeZone,
    },
  });
  const {setError} = form;

  const onSubmit = useCallback(
    async (data: WidgetFormData) => {
      const options = getWidgetFromWidgetFormData(data);
      const client = getXbsKiteClient();

      try {
        await client.send(new CreateWidgetCommand(options));

        navigate(KitePath.base);
      } catch (error) {
        // @ts-ignore
        setError('name', {message: error.toString()});
      }
    },
    [navigate],
  );

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={KitePath.base}>
          {KITE_TITLE}
        </Typography>
        <Typography color="text.primary">Add New Widget</Typography>
      </Breadcrumbs>
      <WidgetForm
        header={
          <Typography color="text.primary" variant="h5">
            New Widget
          </Typography>
        }
        form={form}
        onSubmit={onSubmit}
      />
    </Box>
  );
}
