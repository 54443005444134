export default function getTimeZoneInEtcFormat() {
  try {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const sign = offsetMinutes > 0 ? '-' : '+';

    return `Etc/GMT${sign}${offsetHours}`;
  } catch {
    return 'Etc/GMT';
  }
}
