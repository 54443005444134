import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import getWorkHoursTimeSlots from '../../helpers/getWorkHoursTimeSlots';
import {WidgetFormData} from '../../types';

interface WidgetWorkHoursTimeFrameStartFieldProps {
  form: UseFormReturn<WidgetFormData>;
  path: string;
  till: number;
}

const TIME_SLOTS = getWorkHoursTimeSlots(true);

export default function WidgetWorkHoursTimeFrameStartField(props: WidgetWorkHoursTimeFrameStartFieldProps) {
  const {form, path, till} = props;
  const {control, formState, trigger, watch} = form;
  const {isSubmitting} = formState;

  // const value = watch(path as any) as number | undefined;

  // TODO: Based on the value which is a start minute of the day, e.g. 480 - is 8:00 while 1080 is 18:00
  // Make a select field to work propertly, providing requires values (from 00:00 till 23:45, 15 minutes window).

  return (
    <Controller
      control={control}
      name={path as any}
      rules={{required: true}}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '100%'}} size="small" disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Time</InputLabel>
          <Select
            disabled={isSubmitting}
            labelId={`${name}-label`}
            id={name}
            value={value}
            label="Time"
            onChange={(event) => {
              onChange(event);
              trigger(name);
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {TIME_SLOTS.map(({id, label}) => (
              <MenuItem key={id} value={id} disabled={Boolean(isSubmitting || (till && id >= till))}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
